body {
  margin: 0;
  font-family: "Open Sans", Tahoma, Geneva, sans-serif;
}
.category-container {
  z-index: 999;
  top: 0px;
  right: 0px;
  bottom: 0px;
  /* width: 321px; */
  position: fixed;
  background: -webkit-linear-gradient(
    231.39deg,
    rgba(0, 0, 0, 0.051) 2.61%,
    rgba(0, 0, 0, 0.451) 46.91%,
    rgba(0, 0, 0, 0.051) 97.68%
  );
  background: linear-gradient(
    218.61deg,
    rgba(0, 0, 0, 0.051) 2.61%,
    rgba(0, 0, 0, 0.451) 46.91%,
    rgba(0, 0, 0, 0.051) 97.68%
  );
}
.category-container h1 {
  height: 10vh;
  margin: 0;
}
.category {
  display: flex;
  /* height: 90vh; */
  /* overflow-y: scroll; */
  /* flex-wrap: wrap; */
  overflow-x: hidden;
  overflow-y: auto;
  white-space: normal;
  flex-direction: column;
}
.dropbtn {
  color: #006aff;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  align-self: center;
}
.category-btn {
  background-color: #ffffff;
  margin: 20px;
  cursor: pointer;
  padding: 5px 0 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 34px;
}
.category-btn .icon {
  width: 43px;
  height: 28px;
}
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
  /* height: 75px; */
}
.dropdown-content {
  position: absolute;
  left: auto;
  top: -4px;
  margin: 0px;
  right: 0px;
  bottom: auto;
  transform: translate(-313px, 24px);
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.thumbnails {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.items {
  width: 133px;
  height: 78px;
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  overflow-y: hidden;
}
.sub-category-section img {
  width: 100%;
}
.sub-category-section {
  padding: 24px 10px 0;
}
.heading {
  margin: 0 0 8px;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.4);
}
.sub-category-section.active .heading {
  border-color: #fff;
}
.heading span {
  background-color: hsla(0, 0%, 100%, 0.4);
  color: #0a0a14;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.sub-category-section.active .heading span {
  background-color: #fff;
}
.title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.7);
}
label {
  vertical-align: middle;
  cursor: pointer;
}
.items.active {
  border-color: #006aff;
}
.items.active .title {
  color: #0a0a14;
  background-color: #fff;
}
.category-title {
  display: flex;
  padding: 11.2px 24px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a33;
  border-bottom: 1px solid rgb(209, 209, 213);
}
.category-title img {
  width: 24px;
  margin-right: 10px;
}
.dropdown-content .item img {
  width: 24px;
}
.main-category-items {
  margin-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
}
.main-category-items .item .tick {
  background-image: url(icons/tick.svg);
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  display: none;
}
.main-category-items .item.active .tick {
  display: block;
}
.main-category-items .item.active .gap {
  display: none;
}
.main-category-items .item {
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 150px;
  color: #2a2a33;
  cursor: pointer;
  border-radius: 4px;
}
.main-category-items .item:hover {
  background: rgb(213, 243, 249);
}
.main-category-items img {
  margin-right: 8px;
}
.main-category-items .gap {
  width: 32px;
}
.simple-main-cat-wrapper {
  width: 300px;
}
.layer-btn {
  display: none;
}
.maincategory-content-slider {
  max-height: calc(100vh - 130px);
}
.new-nav {
  right: 310px;
  position: fixed;
  bottom: 60px;
}
@media only screen and (max-width: 1080px) {
  .layer-btn {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 4px;
    border-radius: 4px;
    position: absolute;
    bottom: 136px;
    left: 50px;
    white-space: nowrap;
    /* margin-left: 8px; */
  }
  .layer-btn:hover {
    cursor: pointer;
  }
  .layer-btn span {
    color: rgb(255, 255, 255);
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 144px;
  }
  .dropdown {
    border-bottom: unset;
  }
  .maincategory-content-slider {
    max-height: calc(100vh - 300px);
  }
  .dropdown-content {
    transform: translate(0px, -57px);
    position: relative;
    width: fit-content;
    /* transform: translate(10px, -72vh); */
    /* transform: unset; */
    right: unset;
    left: 10px;
    /* top: calc(-100vh + 140px); */
    top: 10px;
    /* position: fixed; */
  }
  .category-btn {
    display: none;
  }
  .category {
    flex-direction: unset;
    overflow-x: unset;
    overflow-y: unset;
    padding-bottom: 10px;
  }
  .category-container {
    top: unset;
    width: 100vw;
    bottom: 0;
    background: unset;
  }
  .sub-category-section {
    padding: 8px 10px 0;
  }
  .simple-main-cat-wrapper {
    width: 100vw;
    background: linear-gradient(
      218.61deg,
      rgba(0, 0, 0, 0.051) 2.61%,
      rgba(0, 0, 0, 0.451) 46.91%,
      rgba(0, 0, 0, 0.051) 97.68%
    );
  }
  .thumbnails {
    flex-wrap: unset;
  }
  .layer-svg {
    display: inline-block;
    vertical-align: top;
    stroke: currentcolor;
    fill: currentcolor;
    color: rgb(255, 255, 255);
    height: 24px;
    width: 24px;
  }
  @media only screen and (orientation: landscape) {
    .new-nav {
      right: 190px;
      position: fixed;
      bottom: 60px;
    }
    .layer-btn {
      display: none;
    }
    .category-container {
      background: linear-gradient(
        218.61deg,
        rgba(0, 0, 0, 0.051) 2.61%,
        rgba(0, 0, 0, 0.451) 46.91%,
        rgba(0, 0, 0, 0.051) 97.68%
      );
    }
    .dropdown {
      display: block;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
    }
    .dropbtn {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 80px;
    }
    .dropdown-content {
      transform: translate(-172px, 24px);
      left: auto;
      top: -4px;
      right: 0px;
      position: absolute;
      width: unset;
    }
    .maincategory-content-slider {
      max-height: calc(100vh - 130px);
    }
    .category-btn {
      display: flex;
      white-space: nowrap;
    }
    .category {
      display: block;
    }
    .category-container {
      top: 0;
      width: unset;
      width: 180px;
    }
    .sub-category-section {
      padding: 24px 24px 0;
    }
    .thumbnails {
      display: block;
      width: 133px;
    }
    .items {
      margin-top: 8px;
    }
    .simple-main-cat-wrapper {
      width: 177px;
      height: 100vh;
    }
  }
}
