* {
  padding: 0;
  margin: 0;
  /* Disables blue android touch highlight  */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

:root {
  --scroll-color: #0a0a0a;
  --bottom-nav-right: 24px;
  --bottom-nav-bottom: 138px;
}

html {
  /* Disable over scrollling effect on macOS */
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden {
  display: none;
}

canvas {
  display: block;
}

.scrollDiv {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.3s ease;
}

.scrollDiv.scrollDivIdle {
  transform: translateY(60px);
}

.topNav {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s ease;
}

.topNavIdle {
  transform: translateY(-60px);
  opacity: 0;
}

.topNav.logo {
  display: block;
  margin: 0;
  top: 3%;
  left: 3%;
  z-index: 100;
  position: fixed;
}

.bottomNav {
  display: flex;
  bottom: 60px;
  position: absolute;
  transition: 0.2s ease;
}

.bottomNavIdle {
  transform: translateY(124px);
  opacity: 0;
}

.icon-container {
  /* position: absolute; */
  display: flex;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  outline: none;
  justify-content: center;
  align-items: center;
}

.topNav.icon-container {
  top: 24px;
  right: 24px;
}

.icon-container img {
  display: block;
}

.icon-container:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.touch:hover {
  background-color: transparent;
}

@keyframes btnAnim {
  from {
    background-color: rgba(255, 255, 255, 0.2);
  }
  to {
    background-color: rgba(255, 255, 255, 0);
  }
}

.icon-container.red {
  animation: btnAnim 0.3s ease-in;
}

.autoplay {
  opacity: 1;
  left: 8px;
  /* margin-bottom: 24px; */
  bottom: var(--bottom-nav-bottom);
  position: absolute;
  border-radius: 100%;
  /* padding: 0 8px; */
  /* padding: 3px; */
}
.autoplay:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.btn-group {
  display: flex;
}

.bottomNav.btn-group {
  right: var(--bottom-nav-right);
  /* margin-bottom: 24px; */
  bottom: var(--bottom-nav-bottom);
  position: fixed;
}
.gyro {
  display: flex;
  margin-right: 16px;
}

.gCardboard {
  margin-right: 16px;
  /* height: 80%; */
}
.autoplayIdle {
  /* transform: translateY(60px); */
}
.progress-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: inherit; */
  padding: 0;
  /* margin-left: 10px; */
  margin-bottom: 2px;
  left: 10px;
}
.progress-ring__circle {
  transition: 0.8s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.progress-ring__circle_reset {
  transition: 0s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.progress-ring__circle_default {
  transition: 0.6s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.floor-plan-btn {
  position: fixed;
  padding: 5px;
  border-radius: 4px;
  bottom: 177px;
  left: 10px;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
/* .touch:hover {
  background-color: transparent;
} */
