
.annotationMainContainer{
  height: 100vh;
  min-width: 360px;
  width: 100%;
  background-color: rgba(53, 53, 53, 0.8);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.annotationContainer{
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  max-width: 60%;
  max-height: 90%;
  background-color: black;
  position: relative;
}
.annotationImageContainer{
  border-radius: 7px;
  width: 55vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

}

.annotationTextContainer{
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-height: 90%;
  padding-right: 5%;

}

.annotationTitle {
  color: white;
}
.annotationText {
  color: white;
  padding: 5px;
  line-height: 25px;
  overflow-y: auto;
}
.annotationImage{
  max-height: 85%;
  width: 55vh;
  padding: 5%;
}
 .annotationClose{
   position: absolute;
   top: 0;
   right: 0;
   padding: 12px;
   color: white;
 }
.annotationClose img {
  height: 20px;
  width: 20px;
}
.closeIcon {
  font-size: 20px;
}


@media screen and (max-width: 750px) {
  .annotationContainer{
    overflow-y: auto;
    flex-direction: column;
    min-width: 90%;
    display: flex;
    justify-content: flex-start;
  }
  .annotationTitle {
  text-align: center;
  }
  .annotationImage{
    max-width:75%;
    max-height:75%;
    min-width: 50%;
    min-height: 70%;
  }
  .annotationText {

  }
  .annotationImageContainer{
    width: 100%;
    height: inherit;
    justify-content: center;
  }

  .annotationTextContainer{
    scroll-behavior: smooth;
    max-width: 100%;
    text-align: center;

  }
  .annotationTextContainer::-webkit-scrollbar { width: 0 !important }
  .annotationTextContainer { overflow: -moz-scrollbars-none; }
  .annotationTextContainer { -ms-overflow-style: none; }
}
