body {
  margin: 0;
  font-family: "Open Sans", Tahoma, Geneva, sans-serif;
}
::-webkit-scrollbar {
  display: none;
}
.simplebar-scrollbar::before {
  background-color: var(--scroll-color);
  opacity: .6;
}
.simplebar-scrollbar.simplebar-visible:before {
  background-color: var(--scroll-color);
  opacity: .8;
}
.subcategory-container {
  z-index: 2;
  top: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
  background: -webkit-linear-gradient(
    231.39deg,
    rgba(0, 0, 0, 0.051) 2.61%,
    rgba(0, 0, 0, 0.451) 46.91%,
    rgba(0, 0, 0, 0.051) 97.68%
  );
  background: linear-gradient(
    218.61deg,
    rgba(0, 0, 0, 0.051) 2.61%,
    rgba(0, 0, 0, 0.451) 46.91%,
    rgba(0, 0, 0, 0.051) 97.68%
  );
}
.subcategory-container h1 {
  height: 10vh;
  margin: 0;
}
.category-items {
  display: flex;
  /* height: 90vh; */
  /* overflow-y: scroll; */
  /* flex-wrap: wrap; */
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* white-space: normal; */
  flex-direction: column;
}
.simplebar-vertical .simplebar-scrollbar {
  margin-right: 1px;
}
.subcategory {
}
.subcategory-items {
  /* width: 133px; */
  min-width: 133px;
  height: 78px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  overflow-y: hidden;
}
.sub-category img {
  width: 100%;
}
.sub-category {
  padding: 0 10px;
}
.header {
  margin: 20px 12px;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.4);
  border-color: #fff;
}
.header span {
  background-color: hsla(0, 0%, 100%, 0.4);
  color: #0a0a14;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
}
.title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.7);
}
label {
  vertical-align: middle;
  cursor: pointer;
}
.subcategory-items.active {
  border-color: #006aff;
}
.subcategory-items.active .title {
  color: #0a0a14;
  background-color: #fff;
}
.category-title {
  display: flex;
  padding: 11.2px 24px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a33;
  border-bottom: 1px solid rgb(209, 209, 213);
}
.category-title img {
  width: 24px;
  margin-right: 10px;
}
.simple-main-wrapper {
  width: 160px;
}
@media only screen and (max-width: 1080px) {
  .header {
    margin: 6px 12px;
  }
  .subcategory {
    display: flex;
    gap: 5px;
  }
  .subcategory-container {
    top: unset;
    width: 100vw;
    bottom: 0;
  }
  .simple-main-wrapper {
    width: 100vw;
  }
  .subcategory-items {
    margin-right: 6px;
  }
}
@media only screen and (orientation: landscape) {
  .header {
    margin: 20px 12px;
  }
  .subcategory {
    display: block;
    gap: 5px;
  }
  .subcategory-container {
    top: 0;
    width: unset;
  }
  .subcategory-items {
    margin-right: unset;
  }
  .simple-main-wrapper {
    width: 160px;
  }
}
