/* @import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed:400,500|IBM+Plex+Sans:300,400,600"); */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.backdrop {
  position: absolute;
  overflow: hidden;
  z-index: -1;
}

.backdrop img {
  filter: blur(6px) brightness(0.3);
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  transform: scale(1.1);
  transition: 0.3s ease;
}

.clickable {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.clickable:hover .playBtn {
  opacity: 1;
}

.clickable:hover .backdrop img {
  filter: blur(6px) brightness(0.6);
}

img {
  display: block;
}
.logo {
  height: 60px;
  object-fit: contain;
  margin-bottom: 24px;
}

.playBtn {
  height: 48px;
  width: 48px;
  align-self: center;
  margin-bottom: 24px;
  transition: 0.3s ease;
  opacity: .6;
}

h1 {
  font-family: sans-serif;
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  height: 64px;
  margin-bottom: 12px;
  font-family: "IBM Plex Sans", sans-serif;
}

.yellowBar {
  width: 120px;
  height: 3px;
  border-radius: 0 0 7px 7px;
  /* background-color: #ffce01; */
  align-self: center;
}

.footer {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  align-content: center;
  padding: 0 16px;
  justify-content: space-between;
  height: 44px;
  background: linear-gradient(
    303.83deg,
    rgba(0, 0, 0, 0.012) 12.65%,
    rgba(0, 0, 0, 0.8) 98.83%
  );
}

.footer img {
  height: 20px;
}

.footer p {
  color: white;
  opacity: 0.6;
  font-family: sans-serif;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-size: 12px;
}

.isLoading{
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  color: #FFFFFF;
  font-size: 16px;
  padding-top: 12px;
  height: 32px;
  padding-bottom: 100px;
}

@media (max-width:600px){
  .footer {
    padding: 0 10px;
  }
}
