* {
  padding: 0;
  margin: 0;
  /* Disables blue android touch highlight  */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

:root {
  --scroll-color: #0a0a0a;
  --bottom-nav-right: 24px;
  --bottom-nav-bottom: 138px;
}

html {
  /* Disable over scrollling effect on macOS */
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden {
  display: none;
}

canvas {
  display: block;
}

.scrollDiv {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.3s ease;
}

.scrollDiv.scrollDivIdle {
  -webkit-transform: translateY(60px);
          transform: translateY(60px);
}

.topNav {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s ease;
}

.topNavIdle {
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
  opacity: 0;
}

.topNav.logo {
  display: block;
  margin: 0;
  top: 3%;
  left: 3%;
  z-index: 100;
  position: fixed;
}

.bottomNav {
  display: flex;
  bottom: 60px;
  position: absolute;
  transition: 0.2s ease;
}

.bottomNavIdle {
  -webkit-transform: translateY(124px);
          transform: translateY(124px);
  opacity: 0;
}

.icon-container {
  /* position: absolute; */
  display: flex;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  outline: none;
  justify-content: center;
  align-items: center;
}

.topNav.icon-container {
  top: 24px;
  right: 24px;
}

.icon-container img {
  display: block;
}

.icon-container:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.touch:hover {
  background-color: transparent;
}

@-webkit-keyframes btnAnim {
  from {
    background-color: rgba(255, 255, 255, 0.2);
  }
  to {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes btnAnim {
  from {
    background-color: rgba(255, 255, 255, 0.2);
  }
  to {
    background-color: rgba(255, 255, 255, 0);
  }
}

.icon-container.red {
  -webkit-animation: btnAnim 0.3s ease-in;
          animation: btnAnim 0.3s ease-in;
}

.autoplay {
  opacity: 1;
  left: 8px;
  /* margin-bottom: 24px; */
  bottom: 138px;
  bottom: var(--bottom-nav-bottom);
  position: absolute;
  border-radius: 100%;
  /* padding: 0 8px; */
  /* padding: 3px; */
}
.autoplay:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.btn-group {
  display: flex;
}

.bottomNav.btn-group {
  right: 24px;
  right: var(--bottom-nav-right);
  /* margin-bottom: 24px; */
  bottom: 138px;
  bottom: var(--bottom-nav-bottom);
  position: fixed;
}
.gyro {
  display: flex;
  margin-right: 16px;
}

.gCardboard {
  margin-right: 16px;
  /* height: 80%; */
}
.autoplayIdle {
  /* transform: translateY(60px); */
}
.progress-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: inherit; */
  padding: 0;
  /* margin-left: 10px; */
  margin-bottom: 2px;
  left: 10px;
}
.progress-ring__circle {
  transition: 0.8s stroke-dashoffset;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.progress-ring__circle_reset {
  transition: 0s stroke-dashoffset;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.progress-ring__circle_default {
  transition: 0.6s stroke-dashoffset;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.floor-plan-btn {
  position: fixed;
  padding: 5px;
  border-radius: 4px;
  bottom: 177px;
  left: 10px;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
/* .touch:hover {
  background-color: transparent;
} */


.annotationMainContainer{
  height: 100vh;
  min-width: 360px;
  width: 100%;
  background-color: rgba(53, 53, 53, 0.8);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.annotationContainer{
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  max-width: 60%;
  max-height: 90%;
  background-color: black;
  position: relative;
}
.annotationImageContainer{
  border-radius: 7px;
  width: 55vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

}

.annotationTextContainer{
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-height: 90%;
  padding-right: 5%;

}

.annotationTitle {
  color: white;
}
.annotationText {
  color: white;
  padding: 5px;
  line-height: 25px;
  overflow-y: auto;
}
.annotationImage{
  max-height: 85%;
  width: 55vh;
  padding: 5%;
}
 .annotationClose{
   position: absolute;
   top: 0;
   right: 0;
   padding: 12px;
   color: white;
 }
.annotationClose img {
  height: 20px;
  width: 20px;
}
.closeIcon {
  font-size: 20px;
}


@media screen and (max-width: 750px) {
  .annotationContainer{
    overflow-y: auto;
    flex-direction: column;
    min-width: 90%;
    display: flex;
    justify-content: flex-start;
  }
  .annotationTitle {
  text-align: center;
  }
  .annotationImage{
    max-width:75%;
    max-height:75%;
    min-width: 50%;
    min-height: 70%;
  }
  .annotationText {

  }
  .annotationImageContainer{
    width: 100%;
    height: inherit;
    justify-content: center;
  }

  .annotationTextContainer{
    scroll-behavior: smooth;
    max-width: 100%;
    text-align: center;

  }
  .annotationTextContainer::-webkit-scrollbar { width: 0 !important }
  .annotationTextContainer { overflow: -moz-scrollbars-none; }
  .annotationTextContainer { -ms-overflow-style: none; }
}

body {
  margin: 0;
  font-family: "Open Sans", Tahoma, Geneva, sans-serif;
}
.category-container {
  z-index: 999;
  top: 0px;
  right: 0px;
  bottom: 0px;
  /* width: 321px; */
  position: fixed;
  background: linear-gradient(
    218.61deg,
    rgba(0, 0, 0, 0.051) 2.61%,
    rgba(0, 0, 0, 0.451) 46.91%,
    rgba(0, 0, 0, 0.051) 97.68%
  );
}
.category-container h1 {
  height: 10vh;
  margin: 0;
}
.category {
  display: flex;
  /* height: 90vh; */
  /* overflow-y: scroll; */
  /* flex-wrap: wrap; */
  overflow-x: hidden;
  overflow-y: auto;
  white-space: normal;
  flex-direction: column;
}
.dropbtn {
  color: #006aff;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  align-self: center;
}
.category-btn {
  background-color: #ffffff;
  margin: 20px;
  cursor: pointer;
  padding: 5px 0 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 34px;
}
.category-btn .icon {
  width: 43px;
  height: 28px;
}
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
  /* height: 75px; */
}
.dropdown-content {
  position: absolute;
  left: auto;
  top: -4px;
  margin: 0px;
  right: 0px;
  bottom: auto;
  -webkit-transform: translate(-313px, 24px);
          transform: translate(-313px, 24px);
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.thumbnails {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.items {
  width: 133px;
  height: 78px;
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  overflow-y: hidden;
}
.sub-category-section img {
  width: 100%;
}
.sub-category-section {
  padding: 24px 10px 0;
}
.heading {
  margin: 0 0 8px;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.4);
}
.sub-category-section.active .heading {
  border-color: #fff;
}
.heading span {
  background-color: hsla(0, 0%, 100%, 0.4);
  color: #0a0a14;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.sub-category-section.active .heading span {
  background-color: #fff;
}
.title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.7);
}
label {
  vertical-align: middle;
  cursor: pointer;
}
.items.active {
  border-color: #006aff;
}
.items.active .title {
  color: #0a0a14;
  background-color: #fff;
}
.category-title {
  display: flex;
  padding: 11.2px 24px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a33;
  border-bottom: 1px solid rgb(209, 209, 213);
}
.category-title img {
  width: 24px;
  margin-right: 10px;
}
.dropdown-content .item img {
  width: 24px;
}
.main-category-items {
  margin-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
}
.main-category-items .item .tick {
  background-image: url(/vrplayerstatic/media/tick.f1d5a8cc.svg);
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  display: none;
}
.main-category-items .item.active .tick {
  display: block;
}
.main-category-items .item.active .gap {
  display: none;
}
.main-category-items .item {
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 150px;
  color: #2a2a33;
  cursor: pointer;
  border-radius: 4px;
}
.main-category-items .item:hover {
  background: rgb(213, 243, 249);
}
.main-category-items img {
  margin-right: 8px;
}
.main-category-items .gap {
  width: 32px;
}
.simple-main-cat-wrapper {
  width: 300px;
}
.layer-btn {
  display: none;
}
.maincategory-content-slider {
  max-height: calc(100vh - 130px);
}
.new-nav {
  right: 310px;
  position: fixed;
  bottom: 60px;
}
@media only screen and (max-width: 1080px) {
  .layer-btn {
    display: inline-flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 4px;
    border-radius: 4px;
    position: absolute;
    bottom: 136px;
    left: 50px;
    white-space: nowrap;
    /* margin-left: 8px; */
  }
  .layer-btn:hover {
    cursor: pointer;
  }
  .layer-btn span {
    color: rgb(255, 255, 255);
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 144px;
  }
  .dropdown {
    border-bottom: unset;
  }
  .maincategory-content-slider {
    max-height: calc(100vh - 300px);
  }
  .dropdown-content {
    -webkit-transform: translate(0px, -57px);
            transform: translate(0px, -57px);
    position: relative;
    width: -webkit-fit-content;
    width: fit-content;
    /* transform: translate(10px, -72vh); */
    /* transform: unset; */
    right: unset;
    left: 10px;
    /* top: calc(-100vh + 140px); */
    top: 10px;
    /* position: fixed; */
  }
  .category-btn {
    display: none;
  }
  .category {
    flex-direction: unset;
    overflow-x: unset;
    overflow-y: unset;
    padding-bottom: 10px;
  }
  .category-container {
    top: unset;
    width: 100vw;
    bottom: 0;
    background: unset;
  }
  .sub-category-section {
    padding: 8px 10px 0;
  }
  .simple-main-cat-wrapper {
    width: 100vw;
    background: linear-gradient(
      218.61deg,
      rgba(0, 0, 0, 0.051) 2.61%,
      rgba(0, 0, 0, 0.451) 46.91%,
      rgba(0, 0, 0, 0.051) 97.68%
    );
  }
  .thumbnails {
    flex-wrap: unset;
  }
  .layer-svg {
    display: inline-block;
    vertical-align: top;
    stroke: currentcolor;
    fill: currentcolor;
    color: rgb(255, 255, 255);
    height: 24px;
    width: 24px;
  }
  @media only screen and (orientation: landscape) {
    .new-nav {
      right: 190px;
      position: fixed;
      bottom: 60px;
    }
    .layer-btn {
      display: none;
    }
    .category-container {
      background: linear-gradient(
        218.61deg,
        rgba(0, 0, 0, 0.051) 2.61%,
        rgba(0, 0, 0, 0.451) 46.91%,
        rgba(0, 0, 0, 0.051) 97.68%
      );
    }
    .dropdown {
      display: block;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
    }
    .dropbtn {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 80px;
    }
    .dropdown-content {
      -webkit-transform: translate(-172px, 24px);
              transform: translate(-172px, 24px);
      left: auto;
      top: -4px;
      right: 0px;
      position: absolute;
      width: unset;
    }
    .maincategory-content-slider {
      max-height: calc(100vh - 130px);
    }
    .category-btn {
      display: flex;
      white-space: nowrap;
    }
    .category {
      display: block;
    }
    .category-container {
      top: 0;
      width: unset;
      width: 180px;
    }
    .sub-category-section {
      padding: 24px 24px 0;
    }
    .thumbnails {
      display: block;
      width: 133px;
    }
    .items {
      margin-top: 8px;
    }
    .simple-main-cat-wrapper {
      width: 177px;
      height: 100vh;
    }
  }
}

body {
  margin: 0;
  font-family: "Open Sans", Tahoma, Geneva, sans-serif;
}
::-webkit-scrollbar {
  display: none;
}
.simplebar-scrollbar::before {
  background-color: var(--scroll-color);
  opacity: .6;
}
.simplebar-scrollbar.simplebar-visible:before {
  background-color: var(--scroll-color);
  opacity: .8;
}
.subcategory-container {
  z-index: 2;
  top: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
  background: linear-gradient(
    218.61deg,
    rgba(0, 0, 0, 0.051) 2.61%,
    rgba(0, 0, 0, 0.451) 46.91%,
    rgba(0, 0, 0, 0.051) 97.68%
  );
}
.subcategory-container h1 {
  height: 10vh;
  margin: 0;
}
.category-items {
  display: flex;
  /* height: 90vh; */
  /* overflow-y: scroll; */
  /* flex-wrap: wrap; */
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* white-space: normal; */
  flex-direction: column;
}
.simplebar-vertical .simplebar-scrollbar {
  margin-right: 1px;
}
.subcategory {
}
.subcategory-items {
  /* width: 133px; */
  min-width: 133px;
  height: 78px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  overflow-y: hidden;
}
.sub-category img {
  width: 100%;
}
.sub-category {
  padding: 0 10px;
}
.header {
  margin: 20px 12px;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.4);
  border-color: #fff;
}
.header span {
  background-color: hsla(0, 0%, 100%, 0.4);
  color: #0a0a14;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fff;
}
.title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.7);
}
label {
  vertical-align: middle;
  cursor: pointer;
}
.subcategory-items.active {
  border-color: #006aff;
}
.subcategory-items.active .title {
  color: #0a0a14;
  background-color: #fff;
}
.category-title {
  display: flex;
  padding: 11.2px 24px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2a2a33;
  border-bottom: 1px solid rgb(209, 209, 213);
}
.category-title img {
  width: 24px;
  margin-right: 10px;
}
.simple-main-wrapper {
  width: 160px;
}
@media only screen and (max-width: 1080px) {
  .header {
    margin: 6px 12px;
  }
  .subcategory {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
  }
  .subcategory-container {
    top: unset;
    width: 100vw;
    bottom: 0;
  }
  .simple-main-wrapper {
    width: 100vw;
  }
  .subcategory-items {
    margin-right: 6px;
  }
}
@media only screen and (orientation: landscape) {
  .header {
    margin: 20px 12px;
  }
  .subcategory {
    display: block;
    grid-gap: 5px;
    gap: 5px;
  }
  .subcategory-container {
    top: 0;
    width: unset;
  }
  .subcategory-items {
    margin-right: unset;
  }
  .simple-main-wrapper {
    width: 160px;
  }
}

/* @import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed:400,500|IBM+Plex+Sans:300,400,600"); */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.backdrop {
  position: absolute;
  overflow: hidden;
  z-index: -1;
}

.backdrop img {
  -webkit-filter: blur(6px) brightness(0.3);
          filter: blur(6px) brightness(0.3);
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.3s ease;
}

.clickable {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.clickable:hover .playBtn {
  opacity: 1;
}

.clickable:hover .backdrop img {
  -webkit-filter: blur(6px) brightness(0.6);
          filter: blur(6px) brightness(0.6);
}

img {
  display: block;
}
.logo {
  height: 60px;
  object-fit: contain;
  margin-bottom: 24px;
}

.playBtn {
  height: 48px;
  width: 48px;
  align-self: center;
  margin-bottom: 24px;
  transition: 0.3s ease;
  opacity: .6;
}

h1 {
  font-family: sans-serif;
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  height: 64px;
  margin-bottom: 12px;
  font-family: "IBM Plex Sans", sans-serif;
}

.yellowBar {
  width: 120px;
  height: 3px;
  border-radius: 0 0 7px 7px;
  /* background-color: #ffce01; */
  align-self: center;
}

.footer {
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  align-content: center;
  padding: 0 16px;
  justify-content: space-between;
  height: 44px;
  background: linear-gradient(
    303.83deg,
    rgba(0, 0, 0, 0.012) 12.65%,
    rgba(0, 0, 0, 0.8) 98.83%
  );
}

.footer img {
  height: 20px;
}

.footer p {
  color: white;
  opacity: 0.6;
  font-family: sans-serif;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-size: 12px;
}

.isLoading{
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  color: #FFFFFF;
  font-size: 16px;
  padding-top: 12px;
  height: 32px;
  padding-bottom: 100px;
}

@media (max-width:600px){
  .footer {
    padding: 0 10px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
